@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --p-theme-font-family: "Kanit", sans-serif;
  --p-theme-color-primary: #2f5596;
  --p-theme-color-white: #ffffff;
  --p-theme-color-light-gray: #D8D9D7;
  --p-theme-color-gray: #979797;
  --p-theme-color-light-gray-cancled: #bdbdbd;
  --p-theme-color-light-orange: #CC8162;
  --p-theme-color-dark-red: #69150F;
  --p-theme-color-light-red: #98231A;
  --p-theme-color-dark-orange: #B36866;
  --p-theme-shadow-dark: #225a591a;

  --screen-min-width: 500px;
  --screen-max-width: 480px;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
}

input{
  font-family: "Kanit", sans-serif !important;
}

body {
  overflow: scroll;
  margin: 0;
  height: 100%;
  font-family: "Kanit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

@media (min-width: 480px) {
  html {
    width: var(--screen-min-width);
    position: relative;
    margin: 0px auto;
    transform: translate3d(0px, 0px, 0px, 0px);
    background-color: rgba(0, 0, 0, 0.85);
    overflow: hidden;
  }
}

.container{
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  height: 100vh; 
}

.ant-modal-content{
  padding: 0px !important;
}
